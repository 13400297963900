<template>
  <div class="book-content__welcome">
    <div class="book-content__welcome-icon">
      <r-icon
        name="ksodd-tables"
        :size="64"
      />
    </div>
    <div class="book-content__welcome-text">
      <r-title
        type="title-2"
        color-type="subhead"
      >
        {{ $t('book-content-welcome:' + "book:select-ds") }}
      </r-title>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.book-content__welcome {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  min-width: 200px;
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-text {
    font-size: 14px;
    text-align: center;
    margin-top: 16px;
  }
}
</style>
